import { combineReducers } from "redux";
import auth from "./auth";
import loader from "./loader";
import account from "./account";
import lead from "./lead";
import utils from "./utils";
const appReducer = combineReducers({
  auth,
  lead,
  account,
  loader,
  utils
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === "USER_LOGGED_OUT") {
    // we keep a reference of the keys we want to maintain
    // other keys will be passed as undefined and this will call
    // reducers with an initial state

    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
