const INITIAL_STATE = {
  countries: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ALL_COUNTRIES": {
      return {
        ...state,
        countries: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
