/* eslint-disable import/no-anonymous-default-export */
const INITIAL_STATE = {
  info: null,
  users: null
};
export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ACCOUNT_INFO": {
      return {
        ...state,
        info: action.payload
      };
    }
    case "USERS_LIST": {
      return {
        ...state,
        users: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
